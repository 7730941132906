/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import getConfig from "lib/config";

const { publicRuntimeConfig } = getConfig();

const {
  REACT_APP_ENABLE_LOCALES,
  REACT_APP_ENABLE_CHANNELS,
  REACT_APP_FEATURE_CHANNEL_FACEBOOK,
  REACT_APP_FEATURE_CHANNEL_GBM,
  REACT_APP_FEATURE_CHANNEL_VOICE,
  REACT_APP_FEATURE_CHANNEL_WEB,
  REACT_APP_FEATURE_CHANNEL_WHATSAPP,
  REACT_APP_FEATURE_CHANNEL_EMAIL,
  REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_APPOINTMENT_ONOFFICE_CHOICE,
  REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_CONTACT_PERSON_SEARCH_CHOICE,
  REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_LEAD_GENERATION_CHOICE,
  REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_REALESTATE_SEARCH_ONOFFICE_CHOICE,
  REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_REALESTATE_SEARCH_SMPLY_GD_CHOICE,
  REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_TICKET_NODE_CHOICE,
  REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_VALUATION_CHOICE,
  REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_BRICK_CHOICE,
  REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_FAQ_CHOICE,
  REACT_APP_UNLEASH_ENABLED,
  REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_JUMP_CHOICE,
  REACT_APP_TENANT,
} = publicRuntimeConfig;
import { useFlag } from "@unleash/proxy-client-react";
import { useTranslations } from "next-intl";

export const useFeatureFlag = (name) => {
  if (REACT_APP_UNLEASH_ENABLED === "1") {
    return useFlag(name);
  } else {
    return true;
  }
};

export const useLocales = () => {
  const enabledLanguages = {
    de: useFeatureFlag("language.de"),
    en: useFeatureFlag("language.en"),
    fr: useFeatureFlag("language.fr"),
    nl: useFeatureFlag("language.nl"),
    sv: useFeatureFlag("language.sv"),
    tr: useFeatureFlag("language.tr"),
    ru: useFeatureFlag("language.ru"),
    pl: useFeatureFlag("language.pl"),
    ar: useFeatureFlag("language.ar"),
    uk: useFeatureFlag("language.uk"),
    es: useFeatureFlag("language.es"),
    it: useFeatureFlag("language.it"),
  };

  const filteredLocales = () => {
    let locales = REACT_APP_ENABLE_LOCALES.split(",");

    return locales.filter((e) => enabledLanguages[e]);
  };

  return {
    availableLocales: filteredLocales(),
  };
};

export const useDialogueEditorNodes = () => {
  return {
    ticket_node_choice:
      REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_TICKET_NODE_CHOICE === "1" &&
      useFeatureFlag("dialogue_editor.node.ticket"),
    appointment_onoffice_choice:
      REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_APPOINTMENT_ONOFFICE_CHOICE ===
        "1" && useFeatureFlag("dialogue_editor.node.appointment_onoffice"),
    contact_person_search_choice:
      REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_CONTACT_PERSON_SEARCH_CHOICE ===
        "1" && useFeatureFlag("dialogue_editor.node.contact_person_search"),
    lead_generation_choice:
      REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_LEAD_GENERATION_CHOICE === "1" &&
      useFeatureFlag("dialogue_editor.node.lead_generation"),
    realestate_search_smply_gd_choice:
      REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_REALESTATE_SEARCH_SMPLY_GD_CHOICE ===
        "1" &&
      useFeatureFlag("dialogue_editor.node.realestate_search_smply_gd"),
    realestate_search_onoffice_choice:
      REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_REALESTATE_SEARCH_ONOFFICE_CHOICE ===
        "1" &&
      useFeatureFlag("dialogue_editor.node.realestate_search_onoffice"),
    valuation_choice:
      REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_VALUATION_CHOICE === "1" &&
      useFeatureFlag("dialogue_editor.node.valuation"),
    brick_choice:
      REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_BRICK_CHOICE === "1" &&
      useFeatureFlag("dialogue_editor.node.brick"),
    faq_choice:
      REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_FAQ_CHOICE === "1" &&
      useFeatureFlag("dialogue_editor.node.faq"),
    jump_choice:
      REACT_APP_FEATURE_SHOW_DIALOGUE_EDITOR_JUMP_CHOICE === "1" &&
      useFeatureFlag("dialogue_editor.node.jump"),
    decision_tree: REACT_APP_TENANT === "aar",
    contract_question: REACT_APP_TENANT === "aar",
    valuemation_ticket_choice: REACT_APP_TENANT === "aar",
    cs_authentication_choice: REACT_APP_TENANT === "aar",
  };
};

export const useChannels = () => {
  const {
    webEnabled,
    whatsappEnabled,
    facebookEnabled,
    googleBusinessMessagesEnabled,
    voiceEnabled,
    emailEnabled,
  } = useEnabledChannels();

  const filteredChannels = () => {
    let channels = REACT_APP_ENABLE_CHANNELS.split(",");
    return channels.filter((e) => {
      if (e === "web") {
        return webEnabled;
      }
      if (e === "voice") {
        return voiceEnabled;
      }
      if (e === "whatsapp") {
        return whatsappEnabled;
      }
      if (e === "google_business_messages") {
        return googleBusinessMessagesEnabled;
      }
      if (e === "facebook") {
        return facebookEnabled;
      }
      if (e === "emails") {
        return emailEnabled;
      }
    });
  };

  return {
    availableChannels: filteredChannels(),
  };
};

export const useEnabledChannels = () => ({
  webEnabled:
    REACT_APP_FEATURE_CHANNEL_WEB === "1" &&
    useFeatureFlag("dialogue_editor.channel.web"),
  whatsappEnabled:
    REACT_APP_FEATURE_CHANNEL_WHATSAPP === "1" &&
    useFeatureFlag("dialogue_editor.channel.whatsapp"),
  voiceEnabled:
    REACT_APP_FEATURE_CHANNEL_VOICE === "1" &&
    useFeatureFlag("dialogue_editor.channel.voice"),
  googleBusinessMessagesEnabled:
    REACT_APP_FEATURE_CHANNEL_GBM === "1" &&
    useFeatureFlag("dialogue_editor.channel.google_business_messages"),
  facebookEnabled:
    REACT_APP_FEATURE_CHANNEL_FACEBOOK === "1" &&
    useFeatureFlag("dialogue_editor.channel.facebook"),
  emailEnabled:
    REACT_APP_FEATURE_CHANNEL_EMAIL === "1" &&
    useFeatureFlag("dialogue_editor.channel.email"),
});

export const injectLocales = (Component) => {
  // eslint-disable-next-line react/display-name
  return (props) => {
    const { availableLocales } = useLocales();
    const { availableChannels } = useChannels();
    const t = useTranslations();
    return (
      <Component
        {...props}
        availableLocales={availableLocales}
        availableChannels={availableChannels}
        t={t}
      />
    );
  };
};
