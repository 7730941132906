import React, { useEffect, useState } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import { useRouter } from "next/router";
import StorageIcon from "@mui/icons-material/Storage";
import { firstLetterCapitalize } from "../../lib/util";
import useUserGroups from "../../hooks/useUserGroups";
import { useDispatch } from "react-redux";

interface TenantSelectProps extends SelectProps {
  redirectRouterArgs?: any;
}

export const TenantSelect = (props: TenantSelectProps) => {
  const { redirectRouterArgs, ...selectProps } = props;
  const router = useRouter();
  const { locale, pathname, asPath, query } = router;
  const { tenant = "" }: { tenant?: string } = query;
  const [value, setValue] = useState<string>("");
  const groups = useUserGroups();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!value && groups && groups.length > 0) {
      if (tenant) setValue(firstLetterCapitalize(tenant));
      else setValue(groups[0]);
    }
  }, [groups, value, tenant]);

  const handleOptionSelect = (event: SelectChangeEvent<unknown>) => {
    const v = event.target.value as typeof value;
    setValue(v);
    const routerArgs = redirectRouterArgs ?? { pathname, query };
    routerArgs.query.tenant = v;
    router.push(routerArgs).then(() => {
      router.reload();
    });
  };

  return (
    <Select
      value={value}
      onChange={handleOptionSelect}
      placeholder="Group"
      size="small"
      {...selectProps}
    >
      {groups?.map((option) => (
        <MenuItem key={option} value={option}>
          <ListItemIcon
            sx={{
              height: 18,
              minWidth: 10,
              marginRight: 1,
              verticalAlign: "middle",
            }}
          >
            <StorageIcon sx={{ fontSize: "1rem" }} />
          </ListItemIcon>
          <ListItemText
            sx={{ display: "inline-flex", margin: 0 }}
            primary={option}
          />
        </MenuItem>
      ))}
    </Select>
  );
};
